<!--
 * @Description: 
 * @Version: 1.0
 * @Author: YaoDongxin
 * @Date: 2022-11-08 18:19:45
 * @LastEditors: YaoDongxin
 * @LastEditTime: 2022-11-16 10:57:56
-->
<template>
  <el-dialog
    :title="(type == 'create' ? '新增' : '編輯')+title"
    :visible.sync="showDialog"
    width="40%"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div v-loading="loading">
      <el-form label-width="80px" :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="廣告名稱" prop="title">
          <el-input
            v-model="form.title"
            placeholder="请输入廣告名稱"
          ></el-input>
        </el-form-item>
        <el-form-item label="廣告圖片(PC端)" prop="img" ref="imgItem">
          <uploadImg
            :multiple="false"
            @confirm="confirmImg"
            :val="form.img"
          ></uploadImg>
          <span class="color-999">建議尺寸1920*386px，大小2M以內</span>
        </el-form-item>
        <el-form-item label="廣告圖片(移動端)" prop="img" ref="imgItemM">
          <uploadImg
            :multiple="false"
            @confirm="confirmImgM"
            :val="form.img_m"
          ></uploadImg>
          <span class="color-999">建議尺寸960*386px，大小2M以內</span>
        </el-form-item>
        <el-form-item label="鏈接地址">
          <el-input
            v-model="form.link"
            placeholder="请输入鏈接地址，選填"
          ></el-input>
        </el-form-item>
        <el-form-item label="備註">
          <el-input
            v-model="form.note"
            placeholder="请输入備註信息，選填"
          ></el-input>
        </el-form-item>
        <el-form-item label="顯示權重">
          <el-input-number
            v-model="form.sort"
            label="顯示權重"
          ></el-input-number>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="confirm()">保 存</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "create",
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      title: "首頁橫幅廣告",
      showDialog: false,
      rules: {
        title: {
          required: true,
          message: "必填項",
          trigger: "blur",
        },
        img: {
          required: true,
          message: "必填項",
          trigger: "blur",
        },
        img_m: {
          required: true,
          message: "必填項",
          trigger: "blur",
        },
      },
      form: {
        title: "",
        img: "",
        img_m: "",
        link: "",
        note: "",
        sort: 1,
      },
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
      // 打开弹窗的时候请求数据
      if (val) {
        if (this.type == "edit") {
          this.getData(this.item.id)
        } else {
          this.form = {
            title: "",
            img: "",
            img_m: "",
            link: "",
            note: "",
            sort: 1,
          }
        }
      }
    },
  },
  methods: {
    async getData(id) {
      this.loading = true
      let res = await this.api.ads.getOneHomeIndex(id)
      this.loading = false
      if (res && res.code == 0) {
        for (let p in this.form) {
          this.form[p] = res.data[p]
        }
        this.form.id = this.item.id
      }
    },
    confirmImg(arr) {
      let file = arr.files[0]
      if (file.size > 2 * 1024 * 1024) {
        this.$message.warning("圖片大小不能超過 2MB!")
        return
      }
      let formData = new FormData()
      formData.append("file", file)
      this.api.system.uploadFile(formData).then((res) => {
        if (res && res.code == 0) {
          this.$refs["imgItem"].clearValidate()
          this.form.img = res.data
        }
      })
    },
    confirmImgM(arr) {
      let file = arr.files[0]
      if (file.size > 2 * 1024 * 1024) {
        this.$message.warning("圖片大小不能超過 2MB!")
        return
      }
      let formData = new FormData()
      formData.append("file", file)
      this.api.system.uploadFile(formData).then((res) => {
        console.log(res)
        if (res && res.code == 0) {
          this.$refs["imgItemM"].clearValidate()
          this.form.img_m = res.data
        }
      })
    },
    close() {
      this.$emit("close")
    },
    confirm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$emit("confirm", this.form)
        }
      })
    },
  },
}
</script>
<style scoped>
/deep/.el-form-item__label {
  line-height: 20px;
  align-items: center;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
</style>
